import * as React from "react";
import { Header, Icon, Grid, Button } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const NotFoundPage = () =>
    <Grid centered verticalAlign="middle"
      style={{
        minHeight: "700px",
        }}
      >
      <Grid.Column>
        <Grid.Row style={{textAlign: "center"}}>
          <Icon name="marker" size="huge"/>
          <Header as="h1">Whaa, 404, four oh FOUR!</Header>
          <Header as="h2">The construction budget constraints are too tight!</Header>
          <Button as="a" href="https://donate.skymake.io" target="_blank" rel="noopener" color="red" primary size="huge">
          Want to support the efforts? <Icon name="paypal" />
          </Button>
        </Grid.Row>
      </Grid.Column>
    </Grid>;

export default withLayout(NotFoundPage);
